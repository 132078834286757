import React, { useState, useContext } from "react";
import { UserInfoContext } from "../../containers/userInfo";
import { calculateAffinity } from "../../utils/calculateAffinity";
import { STATIC_ASSET_PREFIX } from "../../utils/constants";
import SVG from "react-inlinesvg";
import Dictionary from '@docomodigital/js-dictionary';

export default function NameInsert(props) {
  // Context to set user info in a state variables
  const [userInfo, setUserInfo] = useContext(UserInfoContext);
  const [showResult, setShowResult] = useState(true);

  let result = calculateAffinity(
    userInfo.nameA,
    userInfo.nameB,
    userInfo.signA.id,
    userInfo.signB.id
  );

  return (
    <React.Fragment>
      <div className='opacity'>
        {/* {<button onClick={() => setUserInfo({ step: 1 })}>back to home</button>}*/}
        <div className="result">
          <h2>{Dictionary.get("WEBAPP_IFORTUNEFRAME_RESULT")}</h2>
          <div className="box-result">

            <div className='box-result-signs'>
              <SVG src={userInfo.signA.img && userInfo.signA.img} />
              <img src={userInfo.sexA.img} alt={Dictionary.get("WEBAPP_IFORTUNEFRAME_YOU")} />
            </div>

            <div className='heart-big'> <SVG src={`${STATIC_ASSET_PREFIX}/img/heart.svg`} />
              <p className={
                showResult && result
                  ? "opacity"
                  : "opacity-down"
              }>{showResult && result}</p>
            </div>

            <div className='box-result-signs'>
              <SVG src={userInfo.signB.img && userInfo.signB.img} />
              <img src={userInfo.sexB.img} alt={Dictionary.get("WEBAPP_IFORTUNEFRAME_PARTNER")} />
            </div>

          </div>
        </div>

        <h3>{Dictionary.get("WEBAPP_IFORTUNEFRAME_INSERT_NAMES")}</h3>
        <ul className="form-area">
          <li>
            <input
              type="text"
              placeholder={Dictionary.get("WEBAPP_IFORTUNEFRAME_YOU")}
              onChange={(e) => {
                setShowResult(false);
                setUserInfo({
                  ...userInfo,
                  nameA: e.target.value,
                });
              }}
            />
          </li>
          <li>
            <input
              type="text"
              placeholder={Dictionary.get("WEBAPP_IFORTUNEFRAME_PARTNER")}
              onChange={(e) => {
                setShowResult(false);
                setUserInfo({
                  ...userInfo,
                  nameB: e.target.value,
                });
              }}
            />
          </li>
        </ul>
        <br />

        {userInfo.signA && userInfo.signB && (
          <button
            onClick={() => {
              setShowResult(true);
              calculateAffinity();
            }}
          >
            {Dictionary.get("WEBAPP_IFORTUNEFRAME_CALCULATE")}
          </button>
        )}
      </div>
    </React.Fragment>
  );
}
