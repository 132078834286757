import React, { useState, useRef, useEffect } from 'react';
import { STATIC_ASSET_PREFIX } from '../utils/constants';
import filterKeys from '../utils/filterDictionaryKeys';

import sample from 'lodash.sample';

import Dictionary from '@docomodigital/js-dictionary';

export default function LuckyBiscuit() {
  const [currentBiscuitSentence, setCurrentBiscuitSentence] = useState(null);
  const [state, setState] = useState({ step: 'initial', result: null });

  const biscuitOpenAudioUrl = `${STATIC_ASSET_PREFIX}/audio/biscuit1.mp3`;
  const biscuitReadAudioUrl = `${STATIC_ASSET_PREFIX}/audio/biscuit2.mp3`;

  const biscuitOpenAudio = new Audio(biscuitOpenAudioUrl);
  const biscuitReadAudio = new Audio(biscuitReadAudioUrl);

  // timer reference
  let timer = null;

  useEffect(() => {
    return () => clearTimeout(timer);
  }, [timer]);

  // reference to the current state to access in the setTimeout callback
  // https://github.com/facebook/react/issues/14010#issuecomment-433788147
  const stepRef = useRef(state.step);

  const results = filterKeys(
    Dictionary.list(),
    /^WEBAPP_IFORTUNEFRAME_LUCKY_BISCUIT_.*/,
    true
  );

  return (
    <React.Fragment>
      <div className="container">
        <h1>{Dictionary.get('WEBAPP_ORACLE_LUCKYBISCUIT')}</h1>
        {state.step === 'initial' && (
          <div>
            <h3>{Dictionary.get('WEBAPP_ORACLE_LUCKYBISCUIT_TEXT')}</h3>

            <div className="box-img-biscuit">
              <div className="luckybiscuit-circle">
                <img
                  src={`${STATIC_ASSET_PREFIX}/img/biscuit/normal.png`}
                  onClick={() => {
                    biscuitOpenAudio.play();

                    stepRef.current = 'cracked';
                    setState({ result: sample(results), step: 'cracked' });

                    timer = setTimeout(() => {
                      if (stepRef.current !== 'final') {
                        // update containerized state reference
                        stepRef.current = 'final';

                        biscuitReadAudio.play();

                        setState(prevState => {
                          return {
                            ...prevState,
                            step: 'final',
                          };
                        });
                      }
                    }, 1500);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {state.step == 'cracked' && (
          <div>
            <h3>{Dictionary.get('WEBAPP_ORACLE_LUCKYBISCUIT_TEXT')}</h3>
            <div className="box-img-biscuit">
              <div className="luckybiscuit-circle">

                <img
                  src={`${STATIC_ASSET_PREFIX}/img/biscuit/cracked.png`}
                  onClick={() => {
                    clearTimeout(timer);

                    biscuitReadAudio.play();

                    stepRef.current = 'final';
                    setState(prevState => {
                      return {
                        ...prevState,
                        step: 'final',
                      };
                    });
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {state.step == 'final' && (
          <div className="luckybiscuit-result">
            <div className="luckybiscuit-box-result">
              <p>{state.result}</p>
            </div>
            <button
              onClick={() => {
                clearTimeout(timer);

                stepRef.current = 'initial';
                setState({ step: 'initial', result: null });
              }}
            >
              {Dictionary.get('WEBAPP_ASK_AGAIN')}
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
