import React, { useEffect, useState } from "react";
import { STATIC_ASSET_PREFIX } from "../utils/constants";
import filterKeys from "../utils/filterDictionaryKeys";
import getRandomResult from "../utils/getRandomResultFromObject";
import { gsap, Draggable } from "gsap/all";
import Dictionary from "@docomodigital/js-dictionary";

gsap.registerPlugin(Draggable);

export default function MysticCoffee() {
  const [step, setStep] = useState(0);
  const resultsArray = filterKeys(
    Dictionary.list(),
    /^WEBAPP_IFORTUNEFRAME_MYSTIC_COFFEE.*/
  );
  const resultSentence = getRandomResult(resultsArray);

  useEffect(() => {
    Draggable.create(".draggable", {
      bounds: ".container__spoon",
      onDrag: function () {},
      onDragEnd: function () {
        setStep(1);
      },
    });
  });

  return (
    <React.Fragment>
      <div className="container">
        <h2>{Dictionary.get("WEBAPP_ORACLE_MYSTICCOFFEE")}</h2>

        <div className="coffee__container">
          {step === 0 && (
            <React.Fragment>
              <p className="intro__text">
                {Dictionary.get("WEBAPP_ORACLE_MYSTCCOFFE_TEXT")}
              </p>
              <div className="coffee">
                <div className="container__spoon">
                  <div className="spoon__pointer draggable">
                    <div className="spoon"></div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}

          {step === 1 && (
            <React.Fragment>
              <div className="container__result__coffee">
                <p>{resultSentence}</p>
              </div>
              <button className="button__primary" onClick={() => setStep(0)}>
                {Dictionary.get("WEBAPP_ASK_AGAIN")}
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
