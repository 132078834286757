import { loveMatrix } from '../utils/loveMatrix';

const calculateAffinity = (nameA, nameB, signA, signB) => {
  // add day randomness
  let result = null;
  if (
    signA !== null &&
    signA !== undefined &&
    signB !== null &&
    signB !== undefined
  ) {
    result = loveMatrix[signA][signB];
    const d = new Date();
    result +=
      (((d.getDate() + d.getMonth() + d.getYear() + (signA + 1) + (signA + 1)) %
        5) +
        1) *
      (d.getDay % 2 ? 1 : -1);
  }

  // add name compatibility if both names are entered
  let nameSum = 0;
  if (
    nameA !== null &&
    nameA !== undefined &&
    nameB !== null &&
    nameB !== undefined
  ) {
    for (let i = 0; i < nameA.length; i++) {
      if (/^[a-zA-Z0-9]+$/.test(nameA.charAt(i))) {
        nameSum += nameA.charCodeAt(i);
      }
    }
    for (let i = 0; i < nameB.length; i++) {
      if (/^[a-zA-Z0-9]+$/.test(nameB.charAt(i))) {
        nameSum += nameB.charCodeAt(i);
      }
    }
    result += nameSum % 20;
  }

  // limit to 100%
  result = result < 100 ? result : 100;

  return result;
};

export { calculateAffinity };
