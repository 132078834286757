import axios from "axios";

import { DEVEL_COUNTRY } from './constants';

const CONFIG_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? '/v01/config.getvars'
    : `http://${DEVEL_COUNTRY}-ifortune2.i-fortune.com/v01/config.getvars`;
const DICTIONARY_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? '/v01/dictionary.getlist'
    : `http://${DEVEL_COUNTRY}-ifortune2.i-fortune.com/v01/dictionary.getlist`;

const configPromise = axios.get(CONFIG_ENDPOINT);
const dictionaryPromise = axios.get(DICTIONARY_ENDPOINT);

function bootstrap() {
  return Promise.all([configPromise, dictionaryPromise])
    .then(([configRes, dictRes]) => {
      return [configRes.data, dictRes.data];
    })
    .catch((err) => {
      throw err;
    });
}

export default bootstrap;
