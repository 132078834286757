import React, { useEffect, useState } from "react";
import sampleSize from "lodash.samplesize";
import sample from "lodash.sample";

import { STATIC_ASSET_PREFIX } from "../utils/constants";
import { zeroPad } from "../utils/strManip";
import Dictionary from "@docomodigital/js-dictionary";
import filterKeys from "../utils/filterDictionaryKeys";

// TODO: move inside component body?
let runeImages = new Array(25)
  .fill(undefined)
  .map(
    (el, id) => `${STATIC_ASSET_PREFIX}/img/runes/rune${zeroPad(id + 1, 2)}.png`
  );

// TODO: move inside component body?
const runesAudioUrl = `${STATIC_ASSET_PREFIX}/audio/rune_short.mp3`;
const runesAudio = new Audio(runesAudioUrl);

function RunicDivination() {
  const [runes, setRunes] = useState(null);
  const [selectedPanel, setSelectedPanel] = useState("present");

  useEffect(() => {});

  const runePast = filterKeys(
    Dictionary.list(),
    /^WEBAPP_IFORTUNEFRAME_RUNE_PAST_.*/,
    true
  );
  const runePresent = filterKeys(
    Dictionary.list(),
    /^WEBAPP_IFORTUNEFRAME_RUNE_PRESENT_.*/,
    true
  );
  const runeFuture = filterKeys(
    Dictionary.list(),
    /^WEBAPP_IFORTUNEFRAME_RUNE_FUTURE_.*/,
    true
  );

  function drawRunes() {
    runesAudio.play();

    const strings = [sample(runePast), sample(runePresent), sample(runeFuture)];
    const images = sampleSize(runeImages, 3);

    setRunes({
      past: {
        title: Dictionary.get("WEBAPP_ORACLE_RUNE_PRSENT"),
        text: strings[0],
        img: images[0],
      },
      present: {
        title: Dictionary.get("WEBAPP_ORACLE_RUNE_PAST"),
        text: strings[1],
        img: images[1],
      },
      future: {
        title: Dictionary.get("WEBAPP_ORACLE_RUNE_FUTURE"),
        text: strings[2],
        img: images[2],
      },
    });
  }

  const showRunesComponent = runes && (
    <div className="runes-result">
      <div className="runes-images">
        <div
          className={`present${selectedPanel === "present" ? " selected" : ""}`}
          onClick={() => setSelectedPanel("present")}
        >
          <img src={runes.present.img} alt="present" />
        </div>
        <div
          className={`past${selectedPanel === "past" ? " selected" : ""}`}
          onClick={() => setSelectedPanel("past")}
        >
          <img src={runes.past.img} alt="past" />
        </div>
        <div
          className={`future${selectedPanel === "future" ? " selected" : ""}`}
          onClick={() => setSelectedPanel("future")}
        >
          <img src={runes.future.img} alt="future" />
        </div>
      </div>
      <div className="runes-text">
        <h3>{runes[selectedPanel].title}</h3>
        <p>{runes[selectedPanel].text}</p>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="container">
        <h1>{Dictionary.get("WEBAPP_ORACLE_RUNE")}</h1>
        {runes ? (
          <div>
            {showRunesComponent}
            <button
              className="btn-runes"
              onClick={() => {
                setSelectedPanel("present");
                setRunes(null);
              }}
            >
              {Dictionary.get("WEBAPP_ASK_AGAIN")}
            </button>
          </div>
        ) : (
          <div className="center-box runes">
            <h3>{Dictionary.get("WEBAPP_ORACLE_RUNE_TEXT")}</h3>
            <img
              src={`${STATIC_ASSET_PREFIX}/img/runes/runes-bag.png`}
              alt="runes-bag"
              onClick={() => drawRunes()}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default RunicDivination;
