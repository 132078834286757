const randomNumbers = (num, max, sign) => {
  let finalNumbers = [];
  const numberSign = sign === "positive" || !sign ? 1 : -1;

  for (let index = 0; finalNumbers.length < num; index++) {
    let num = generateNumber(max, numberSign);
    if (
      finalNumbers.includes(num) === false &&
      finalNumbers.includes(0) === false
    ) {
      finalNumbers.push(num);
    }
  }

  return finalNumbers;
};

const generateNumber = (max, sign) => {
  return Math.floor(Math.random() * max * sign);
};

export { randomNumbers };
