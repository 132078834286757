import React, { useState } from "react";
import { STATIC_ASSET_PREFIX } from "../utils/constants";
import SVG from "react-inlinesvg";
import { calculateSoulMate } from "../utils/CalculateSoulmate";
//import '../style/app.css';
//import '../style/soulmate.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Dictionary from "@docomodigital/js-dictionary";

const SoulMate = (props) => {
  const signInfo = {
    signA: {
      id: 0,
    },
    signB: {
      id: 0,
    },
  };

  const [signDefault, setSign] = useState(signInfo);
  const [showResult, setShowResult] = useState(false);

  let result = null;

  const moveBoxClass = showResult ? "move-box" : "";
  const blockBox = showResult ? "block-box" : "";

  const signs = [
    [
      Dictionary.get("WEBAPP_IFORTUNEFRAME_ARIES"),
      `${STATIC_ASSET_PREFIX}/img/horoscope-classic-aries.svg`,
    ],
    [
      Dictionary.get("WEBAPP_IFORTUNEFRAME_TAURUS"),
      `${STATIC_ASSET_PREFIX}/img/horoscope-classic-taurus.svg`,
    ],
    [
      Dictionary.get("WEBAPP_IFORTUNEFRAME_GEMINI"),
      `${STATIC_ASSET_PREFIX}/img/horoscope-classic-gemini.svg`,
    ],
    [
      Dictionary.get("WEBAPP_IFORTUNEFRAME_CANCER"),
      `${STATIC_ASSET_PREFIX}/img/horoscope-classic-cancer.svg`,
    ],
    [
      Dictionary.get("WEBAPP_IFORTUNEFRAME_LEO"),
      `${STATIC_ASSET_PREFIX}/img/horoscope-classic-leo.svg`,
    ],
    [
      Dictionary.get("WEBAPP_IFORTUNEFRAME_VIRGO"),
      `${STATIC_ASSET_PREFIX}/img/horoscope-classic-virgo.svg`,
    ],
    [
      Dictionary.get("WEBAPP_IFORTUNEFRAME_LIBRA"),
      `${STATIC_ASSET_PREFIX}/img/horoscope-classic-libra.svg`,
    ],
    [
      Dictionary.get("WEBAPP_IFORTUNEFRAME_SCORPIO"),
      `${STATIC_ASSET_PREFIX}/img/horoscope-classic-scorpio.svg`,
    ],
    [
      Dictionary.get("WEBAPP_IFORTUNEFRAME_SAGITTARIUS"),
      `${STATIC_ASSET_PREFIX}/img/horoscope-classic-sagittarius.svg`,
    ],
    [
      Dictionary.get("WEBAPP_IFORTUNEFRAME_CAPRICORN"),
      `${STATIC_ASSET_PREFIX}/img/horoscope-classic-capricorn.svg`,
    ],
    [
      Dictionary.get("WEBAPP_IFORTUNEFRAME_AQUARIUS"),
      `${STATIC_ASSET_PREFIX}/img/horoscope-classic-aquarius.svg`,
    ],
    [
      Dictionary.get("WEBAPP_IFORTUNEFRAME_PISCES"),
      `${STATIC_ASSET_PREFIX}/img/horoscope-classic-pisces.svg`,
    ],
  ];

  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 681,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
    // disable swiping
    swipe: showResult ? false : true,
    focusOnSelect: showResult ? false : true,
  };

  return (
    <React.Fragment>
      <div className="container">
        <h1>{Dictionary.get("WEBAPP_TITLE_SOULMATE")}</h1>
        {showResult ? (
          <h4>{Dictionary.get("WEBAPP_LOVE_SOULMATE_RESULTS_DESCRIPTION")}</h4>
        ) : (
          <h4>{Dictionary.get("WEBAPP_LOVE_SOULMATE_INSTRUCTIONS")}</h4>
        )}
        <div className={`container-carousel-sign ${blockBox}`}>
          <Slider
            {...slickSettings}
            afterChange={(index) => {
              setSign((prevState) => {
                return {
                  ...prevState,
                  signA: {
                    id: index,
                  },
                };
              });
            }}
          >
            {signs.map((el, id) => {
              return (
                <li key={id}>
                  {/* <div className={el[1]} /> */}
                  <SVG src={el[1]} />
                  <p>{el[0]}</p>
                </li>
              );
            })}
          </Slider>
        </div>
        <div className="block-game-soulmate">
          <div className={`result-soulmate ${moveBoxClass}`}>
            {showResult ? (
              <p className="text-result">
                <span>{showResult}</span>
              </p>
            ) : (
              <p
                className="text-result"
                onClick={() => {
                  result = calculateSoulMate(
                    signDefault.signA.id,
                    signDefault.signB.id
                  );
                  setShowResult(result);
                }}
              >
                <span>{Dictionary.get("WEBAPP_GO")}</span>
              </p>
            )}
          </div>
        </div>
        <div className={`container-carousel-sign ${blockBox}`}>
          <Slider
            {...slickSettings}
            afterChange={(index) => {
              setSign((prevState) => {
                return {
                  ...prevState,
                  signB: {
                    id: index,
                  },
                };
              });
            }}
          >
            {signs.map((el, id) => {
              return (
                <li key={id}>
                  {/* <div className={el[1]} /> */}
                  <SVG src={el[1]} />
                  <p>{el[0]}</p>
                </li>
              );
            })}
          </Slider>
        </div>
        {showResult && (
          <button
            onClick={() => window.location.reload(false)}
            className="opacity-animation"
          >
            {Dictionary.get("WEBAPP_PLAY_AGAIN")}
          </button>
        )}
      </div>
    </React.Fragment>
  );
};

export default SoulMate;
