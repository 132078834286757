const movement = (ball, container, xAxis, yAxis, timeout) => {
  // some factor to scale the speed of the ball
  const factor = 1;
  let timeOld, timeElapsed;
  // the current x position of the ball
  var x = xAxis;
  var y = yAxis;
  var step = 1;
  // the direction in the x dimension (1 or -1)
  var dx = step;
  var dy = step;
  var mov;
  // use requestAnimationFrame in favor of setInterval/setTimeout
  // See: https://css-tricks.com/using-requestanimationframe/

  // check the balls position and set the direction if out of bounds
  const checkBall = () => {
    let width = ball.offsetWidth;
    let height = ball.offsetHeight;
    let cH = container.offsetHeight;
    let cW = container.offsetWidth;
    ball.style.opacity = 1;
    if (x + width >= cW) dx = -step;
    if (x <= 0) dx = step;
    if (y + height >= cH) dy = -step;
    if (y <= 0) dy = step;
  };

  // move the ball by (dx,dy)
  const moveball = (timestamp) => {
    // measure the time elapsed since
    // last call to moveball function
    if (!timeOld) timeOld = timestamp;
    timeElapsed = timestamp - timeOld;
    timeOld = timestamp;

    // calculate ball's position based on
    // movement's direction and time elapsed
    x += dx * timeElapsed * factor;
    y += (dy * timeElapsed * factor) / 3;

    // use CSS transform instead of top and left
    // for performance reasons
    // See: https://www.keycdn.com/blog/animation-performance#there-are-three-main-types-of-css-properties
    ball.style.transform = "translate(" + x + "px, " + y + "px)";

    checkBall();
    // call requestAnimationFrame again
    // like you would do with
    // setTimeout
    mov = requestAnimationFrame(moveball);
  };
  setTimeout(() => {
    requestAnimationFrame(moveball);
  }, timeout);

  cancelAnimationFrame(mov);
};

export { movement };
