import React, { useContext } from 'react';
import { UserInfoContext } from '../../containers/userInfo';
import { STATIC_ASSET_PREFIX } from '../../utils/constants';
import Config from '@docomodigital/js-config';
import Dictionary from '@docomodigital/js-dictionary';

const SimpleModal = ({ classes, text, user, status, onClose }) => {
  const [userInfo, setUserInfo] = useContext(UserInfoContext);
  const sex = [
    [
      Dictionary.get('WEBAPP_IFORTUNEFRAME_MALE'),
      `${STATIC_ASSET_PREFIX}/img/gender/boy.svg`,
    ],
    [
      Dictionary.get('WEBAPP_IFORTUNEFRAME_FEMALE'),
      `${STATIC_ASSET_PREFIX}/img/gender/girl.svg`,
    ],
    [
      Dictionary.get('WEBAPP_IFORTUNEFRAME_ALIEN_MALE'),
      `${STATIC_ASSET_PREFIX}/img/gender/alien-male.svg`,
    ],
    [
      Dictionary.get('WEBAPP_IFORTUNEFRAME_ALIEN_FEMALE'),
      `${STATIC_ASSET_PREFIX}/img/gender/alien-female.svg`,
    ],
  ];

  let sexSelectionList = sex;
  if (Config.get('HIDE_ADDITIONAL_SEX_SELECTION')) {
    sexSelectionList = sex.slice(0, 2);
  }

  if (!status) {
    return null;
  }

  return (
    <div className={classes} data-mipqa="">
      <div className="overlay"></div>
      <div className="modal">
        <div className="close-modal" onClick={onClose}></div>
        <ul>
          {sexSelectionList.map((el, id) => {
            return (
              <li
                key={id}
                value={el[0]}
                onClick={e => {
                  if (user === 1) {
                    setUserInfo({
                      ...userInfo,
                      sexA: { sex: el[0], img: el[1] },
                    });
                    onClose();
                  } else {
                    setUserInfo({
                      ...userInfo,
                      sexB: { sex: el[0], img: el[1] },
                    });
                    onClose();
                  }
                }}
              >
                <img src={el[1]} alt={el[1]} />
                <p>{el[0]}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SimpleModal;
