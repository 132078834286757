const getRandomResult = (obj) => {
  var size = 0;
  var pos = 0;
  var key, randomNumber;
  // Calc size of the object
  for (key in obj) {
    size++;
  }

  // Set a random integer number included in size of the object
  randomNumber = Math.floor(Math.random() * size);

  // Cycle and select random an object
  for (key in obj) {
    if (pos === randomNumber) {
      return obj[key];
    }
    pos++;
  }
  return;
};

export default getRandomResult;
