import React, { useContext } from "react";
import { UserInfoContext } from "../../containers/userInfo";
import SVG from "react-inlinesvg";
import { STATIC_ASSET_PREFIX } from "../../utils/constants";
import Dictionary from '@docomodigital/js-dictionary';


export default function LabelSign(props) {
  const [userInfo, setUserInfo] = useContext(UserInfoContext);
  const labels = [
    [Dictionary.get("WEBAPP_IFORTUNEFRAME_ARIES"), `${STATIC_ASSET_PREFIX}/img/horoscope-classic-aries.svg`],
    [Dictionary.get("WEBAPP_IFORTUNEFRAME_TAURUS"), `${STATIC_ASSET_PREFIX}/img/horoscope-classic-taurus.svg`],
    [Dictionary.get("WEBAPP_IFORTUNEFRAME_GEMINI"), `${STATIC_ASSET_PREFIX}/img/horoscope-classic-gemini.svg`],
    [Dictionary.get("WEBAPP_IFORTUNEFRAME_CANCER"), `${STATIC_ASSET_PREFIX}/img/horoscope-classic-cancer.svg`],
    [Dictionary.get("WEBAPP_IFORTUNEFRAME_LEO"), `${STATIC_ASSET_PREFIX}/img/horoscope-classic-leo.svg`],
    [Dictionary.get("WEBAPP_IFORTUNEFRAME_VIRGO"), `${STATIC_ASSET_PREFIX}/img/horoscope-classic-virgo.svg`],
    [Dictionary.get("WEBAPP_IFORTUNEFRAME_LIBRA"), `${STATIC_ASSET_PREFIX}/img/horoscope-classic-libra.svg`],
    [Dictionary.get("WEBAPP_IFORTUNEFRAME_SCORPIO"), `${STATIC_ASSET_PREFIX}/img/horoscope-classic-scorpio.svg`],
    [
      Dictionary.get("WEBAPP_IFORTUNEFRAME_SAGITTARIUS"),
      `${STATIC_ASSET_PREFIX}/img/horoscope-classic-sagittarius.svg`,
    ],
    [Dictionary.get("WEBAPP_IFORTUNEFRAME_CAPRICORN"), `${STATIC_ASSET_PREFIX}/img/horoscope-classic-capricorn.svg`],
    [Dictionary.get("WEBAPP_IFORTUNEFRAME_AQUARIUS"), `${STATIC_ASSET_PREFIX}/img/horoscope-classic-aquarius.svg`],
    [Dictionary.get("WEBAPP_IFORTUNEFRAME_PISCES"), `${STATIC_ASSET_PREFIX}/img/horoscope-classic-pisces.svg`],
  ];

  return (
    <ul className="box-signs">
      {labels.map((el, id) => {
        return (
          <li
            key={id}
            onClick={() => {
              userInfo.signA.id !== null
                ? setUserInfo({
                  ...userInfo,
                  signB: {
                    id,
                    sign: el[0],
                    img: el[1],
                  },
                })
                : setUserInfo({
                  ...userInfo,
                  signA: {
                    id,
                    sign: el[0],
                    img: el[1],
                  },
                });
            }}
          >
            <SVG src={el[1]} />
            <p>{el[0]}</p>
          </li>
        );
      })}
    </ul>
  );
}
