export default function filterKeys(dict, pattern, toArray = false) {
  let keys = [];
  for (let key in dict) {
    if (dict.hasOwnProperty(key) && pattern.test(key)) {
      keys.push(key);
    }
  }

  const filtered = Object.keys(dict)
    .filter(key => keys.includes(key))
    .reduce((obj, key) => {
      obj[key] = dict[key];
      return obj;
    }, {});

  if (toArray) {
    let arr = [];
    for (let key in filtered) {
      arr.push(filtered[key]);
    }
    return arr;
  }
  return filtered;
}
