import React, { useState, useEffect, useRef } from "react";
import Dictionary from "@docomodigital/js-dictionary";
import { randomNumbers } from "../utils/randomNumbers";
import { movement } from "../utils/bouncingBalls";

export default function WinningNumbers() {
  const [step, setStep] = useState(0);
  const container = useRef();
  const results = randomNumbers(6, 90);

  const ball_0 = useRef();
  const ball_1 = useRef();
  const ball_2 = useRef();
  const ball_3 = useRef();
  const ball_4 = useRef();
  const ball_5 = useRef();

  useEffect(() => {
    if (step === 1) {
      movement(
        ball_0.current,
        container.current,
        Math.floor(Math.random() * (-50 - 200) - 50),
        Math.floor(Math.random() * (-150 - 250) - 150),
        0
      );
      movement(
        ball_1.current,
        container.current,
        Math.floor(Math.random() * (1100 - 900) + 900),
        Math.floor(Math.random() * (150 - 50) + 50),
        300
      );
      movement(
        ball_2.current,
        container.current,
        Math.floor(Math.random() * (-50 - 200) - 50),
        Math.floor(Math.random() * (-150 - 250) - 150),
        600
      );
      movement(
        ball_3.current,
        container.current,
        Math.floor(Math.random() * (1100 - 900) + 900),
        Math.floor(Math.random() * (150 - 50) + 50),
        900
      );
      movement(
        ball_4.current,
        container.current,
        Math.floor(Math.random() * (-50 - 200) - 50),
        Math.floor(Math.random() * (-150 - 250) - 150),
        1200
      );
      movement(
        ball_5.current,
        container.current,
        Math.floor(Math.random() * (1100 - 900) + 900),
        Math.floor(Math.random() * (150 - 50) + 50),
        1500
      );

      setTimeout(() => {
        setStep(2);
      }, 6000);
    }
  });

  return (
    <React.Fragment>
      <div className="container">
        <h2
          dangerouslySetInnerHTML={{
            __html: Dictionary.get("WEBAPP_NUMEROLOGY_LOTTERY"),
          }}
        ></h2>

        <div className="winning__container">
          {step === 0 && (
            <React.Fragment>
              <p
                className="intro__text"
                dangerouslySetInnerHTML={{
                  __html: Dictionary.get("WEBAPP_NUMEROLOGY_LOTTERY_TEXT"),
                }}
              ></p>
              <div className="balls" onClick={() => setStep(1)}></div>
            </React.Fragment>
          )}
          {step === 1 && (
            <React.Fragment>
              <div ref={container} className="container__result__winning">
                <div ref={ball_0} className={`ball ball_0`}></div>
                <div ref={ball_1} className={`ball ball_1`}></div>
                <div ref={ball_2} className={`ball ball_2`}></div>
                <div ref={ball_3} className={`ball ball_3`}></div>
                <div ref={ball_4} className={`ball ball_4`}></div>
                <div ref={ball_5} className={`ball ball_5`}></div>
              </div>
            </React.Fragment>
          )}

          {step === 2 && (
            <React.Fragment>
              <div className="container__result__winning">
                {results &&
                  results.map((number, index) => (
                    <div key={index} className={`circle circle_${index}`}>
                      <div className="big__circle">
                        <h3 className="little__circle">{number}</h3>
                      </div>
                    </div>
                  ))}
              </div>
              <button className="button__primary" onClick={() => setStep(0)}>
                {Dictionary.get("WEBAPP_ASK_AGAIN")}
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
