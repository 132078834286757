const loveMatrix = [
  [75, 50, 70, 40, 80, 50, 60, 20, 80, 20, 70, 50],
  [50, 75, 50, 70, 40, 80, 50, 60, 20, 80, 20, 70],
  [70, 50, 75, 50, 70, 40, 80, 50, 60, 20, 80, 80],
  [40, 70, 50, 75, 50, 70, 40, 80, 50, 60, 20, 80],
  [80, 40, 70, 50, 75, 50, 70, 40, 80, 50, 60, 20],
  [50, 80, 40, 70, 50, 75, 50, 70, 40, 80, 50, 60],
  [60, 50, 80, 40, 70, 50, 75, 50, 70, 40, 80, 50],
  [20, 60, 50, 80, 40, 70, 50, 75, 50, 70, 40, 80],
  [80, 20, 60, 50, 80, 40, 70, 50, 75, 50, 70, 40],
  [20, 80, 20, 60, 50, 80, 40, 70, 50, 75, 50, 70],
  [70, 20, 80, 20, 60, 50, 80, 40, 70, 50, 75, 50],
  [50, 70, 80, 80, 20, 60, 50, 80, 40, 70, 50, 75],
];

export { loveMatrix };
