import React from 'react';
import SignSelect from '../components/SignSelect';
import SexSelect from '../components/SexSelect';
import NameInsert from '../components/NameInsert';

export default function SwitchComponent({ userInfo }) {
  switch (userInfo.step) {
    case 1:
      return <SexSelect />;
    case 2:
      return <SignSelect />;
    case 3:
      console.log(userInfo.step);
      return <NameInsert />;
    default:
      return;
  }
}
