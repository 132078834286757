import { loveMatrix } from './loveMatrix';

const calculateSoulMate = (signA, signB) => {
    // add day randomness
    let result = null;
    if (
        signA !== null &&
        signA !== undefined &&
        signB !== null &&
        signB !== undefined
    ) {
        result = loveMatrix[signA][signB];
        const d = new Date();
        result +=
            (((d.getDate() + d.getMonth() + d.getYear() + (signA + 1) + (signA + 1)) %
                5) +
                1) *
            (d.getDay % 2 ? 1 : -1);
    }

    // limit to 100%
    result = result < 100 ? result : 100;
    console.log(result);

    return result;
};

export { calculateSoulMate };
