import React, { useContext } from 'react';
import { UserInfoContext } from '../containers/userInfo';
import SwitchComponent from '../utils/SwitchComponent';
import { presetUserInfo } from '../containers/userInfo';
import { STATIC_ASSET_PREFIX } from '../utils/constants';
import SVG from 'react-inlinesvg';

import Dictionary from '@docomodigital/js-dictionary';

function LoveCompatibility() {
  const [userInfo, setUserInfo] = useContext(UserInfoContext);

  return (
    <React.Fragment>
      <div className="container">
        <h1 onClick={() => setUserInfo({ ...presetUserInfo })}>
          {userInfo.step === 3 && (
            <div className="back-home opacity">
              <SVG src={`${STATIC_ASSET_PREFIX}/img/navigation_back.svg`} />
            </div>
          )}

          {Dictionary.get('WEBAPP_IFORTUNEFRAME_LOVE_COMPATIBILITY')}
        </h1>
        {<SwitchComponent userInfo={userInfo} />}
      </div>
    </React.Fragment>
  );
}

export default LoveCompatibility;
