import React from 'react';
import ReactDOM from 'react-dom';
import { UserInfoContextProvider } from './containers/userInfo';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import LoveCompatibility from './pages/LoveCompatibility';
import RunicDivination from './pages/RunicDivination';
import MysticCoffee from './pages/MysticCoffee';
import SoulMate from './pages/SoulMate';
import LuckyNumber from './pages/LuckyNumber';
import WinningNumbers from './pages/WinningNumbers';
import LuckyBiscuit from './pages/LuckyBiscuit';

import Dictionary from '@docomodigital/js-dictionary';
import Config from '@docomodigital/js-config';

import bootstrap from './utils/bootstrap';

bootstrap().then(([config, dict]) => {
  Dictionary.init({
    dict,
    showKey: process.env.NODE_ENV === 'production' ? 'standard' : 'missing',
  });

  Config.init({ config });

  ReactDOM.render(
    <Router>
      <React.StrictMode>
        <UserInfoContextProvider>
          <Switch>
            <Route exact path="/">
              <Redirect to="/lovecompatibility" />
            </Route>
            <Route exact path="/lovecompatibility">
              <LoveCompatibility />
            </Route>
            <Route exact path="/mysticcoffee">
              <MysticCoffee />
            </Route>
            <Route exact path="/runicdivination">
              <RunicDivination />
            </Route>
            <Route exact path="/soulmate">
              <SoulMate />
            </Route>
            <Route exact path="/winningnumbers">
              <WinningNumbers />
            </Route>
            <Route exact path="/luckynumber">
              <LuckyNumber />
            </Route>
            <Route exact path="/luckybiscuit">
              <LuckyBiscuit />
            </Route>
          </Switch>
        </UserInfoContextProvider>
      </React.StrictMode>
    </Router>,
    document.getElementById('root')
  );

  reportWebVitals();
});
