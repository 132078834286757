import React, { useContext, useEffect, useState } from "react";
import { UserInfoContext } from "../../containers/userInfo";
import LabelSign from "../LabelSign";
import SVG from "react-inlinesvg";
import Dictionary from "@docomodigital/js-dictionary";

export default function SignSelect(props) {
  // Context to set user info in a state variables
  const [userInfo, setUserInfo] = useContext(UserInfoContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userInfo.signB.id !== null && userInfo.signB.id !== undefined) {
      setLoading(true);
      setTimeout(() => {
        setUserInfo({
          ...userInfo,
          step: 3,
        });
      }, 2000);
    }
  }, [userInfo, setUserInfo]);

  return (
    <React.Fragment>
      <div className="change-step">
        {/* {<button onClick={() => setUserInfo({ step: 1 })}>back to home</button>}*/}
        <h3>{Dictionary.get("WEBAPP_IFORTUNEFRAME_SELECTION_SIGN")}</h3>

        <ul className="general-box">
          <li>
            <strong>{Dictionary.get("WEBAPP_IFORTUNEFRAME_YOU")}:</strong>
            <div
              className={
                userInfo.signA.sign
                  ? "box-sign-selected sign-selected"
                  : "box-sign-selected"
              }
            >
              <SVG src={userInfo.signA.img && userInfo.signA.img} />
            </div>
          </li>
          <li>
            <strong>{Dictionary.get("WEBAPP_IFORTUNEFRAME_PARTNER")}:</strong>
            <div
              className={
                userInfo.signB.sign
                  ? "box-sign-selected sign-selected"
                  : "box-sign-selected"
              }
            >
              <SVG src={userInfo.signB.img && userInfo.signB.img} />
            </div>
          </li>
        </ul>
        {loading && (
          <div className="spinner">
            <div className="heart heart1"></div>
            <div className="heart heart2"></div>
            <div className="heart heart2"></div>
          </div>
        )}
        <LabelSign />
        <br />
      </div>
    </React.Fragment>
  );
}
