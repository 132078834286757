import React, { useState } from 'react';
import Dictionary from '@docomodigital/js-dictionary';

const LuckyNumber = () => {
    const numbers = Array.from({ length: 90 }, (_, i) => i + 1);
    const [isNumber, setNumber] = useState(false);
    const calculateSpace = Math.floor(Math.random() * 90 + 1) * 110 - 4 + 'px';
    const changeStyle = isNumber ? calculateSpace : '0';
    const addMoveClass = isNumber ? 'active-n' : '';
    const addBlockedClass = isNumber ? 'blocked-box' : '';

    return (
        <React.Fragment>
            <div className="container">
                <h1>{Dictionary.get("WEBAPP_NUMEROLOGY_LUCKY")}</h1>
                <h4>{Dictionary.get("WEBAPP_NUMEROLOGY_LUCKYNUMBER_DESCRIPTION")}</h4>
                <div className="circle-number color-circle-number">
                    <div className={`${addBlockedClass}`}></div>
                    <ul className={`move-numbers ${addMoveClass}`} onClick={() => setNumber(!isNumber)} style={{ left: `-${changeStyle}` }}>
                        {isNumber ? (
                            numbers.map((number, i) => {
                                return (<li key={i + 1}> {number} </li>)
                            })
                        ) : (
                                <li className="no-numbers">•••</li>
                            )}
                    </ul>
                </div>
                {isNumber && (
                    <div className="btn-lucky-number">
                        <button
                            onClick={() => window.location.reload(false)}
                            className="btn opacity-animation-slow"
                        >
                            {Dictionary.get("WEBAPP_PLAY_AGAIN")}
                        </button>
                    </div>
                )}


            </div>
        </React.Fragment>
    );
}


export default LuckyNumber;