import React, { useState } from "react";
import { STATIC_ASSET_PREFIX } from "../utils/constants";

const presetUserInfo = {
  sexA: {
    sex: "male",
    img: `${STATIC_ASSET_PREFIX}/img/gender/girl.svg`,
  },
  sexB: {
    sex: "female",
    img: `${STATIC_ASSET_PREFIX}/img/gender/boy.svg`,
  },
  nameA: null,
  nameB: null,
  signA: {
    id: null,
    sign: null,
    img: null,
  },
  signB: {
    id: null,
    sign: null,
    img: null,
  },
  step: 1,
};

const UserInfoContext = React.createContext(presetUserInfo);

const UserInfoContextProvider = (props) => {
  const [info, setInfo] = useState(presetUserInfo);

  return (
    <UserInfoContext.Provider value={[info, setInfo]}>
      {props.children}
    </UserInfoContext.Provider>
  );
};

export { UserInfoContext, UserInfoContextProvider, presetUserInfo };
