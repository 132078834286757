import React, { useContext, useState } from "react";
import { UserInfoContext } from "../../containers/userInfo";
import SimpleModal from "../SimpleModal";
import Dictionary from "@docomodigital/js-dictionary";

export default function SexSelect(props) {
  // Context to set user info in a state variables
  const [userInfo, setUserInfo] = useContext(UserInfoContext);
  const [open, setOpen] = useState({
    status: false,
    user: 1,
  });

  return (
    <React.Fragment>
      <div className="container-first-step opacity">
        <h2>{Dictionary.get("WEBAPP_IFORTUNEFRAME_CHOOSE_SEX")}</h2>
        <div className="first-step">
          <ul>
            <li className="title">
              <h3>{Dictionary.get("WEBAPP_IFORTUNEFRAME_YOU")}</h3>
            </li>

            <li className="gender-image">
              <img
                src={userInfo.sexA.img}
                alt={Dictionary.get("WEBAPP_IFORTUNEFRAME_YOU")}
              />
            </li>
            <li className="select-gender">
              <p onClick={() => setOpen({ status: true, user: 1 })}>
                {Dictionary.get("WEBAPP_IFORTUNEFRAME_CHANGE_SEX")}
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <span className="symbol-plus"></span>
            </li>
          </ul>
          <ul>
            <li className="title">
              <h3>{Dictionary.get("WEBAPP_IFORTUNEFRAME_PARTNER")}</h3>
            </li>
            <li className="gender-image">
              <img
                src={userInfo.sexB.img}
                alt={Dictionary.get("WEBAPP_IFORTUNEFRAME_PARTNER")}
              />
            </li>
            <li className="select-gender">
              <p onClick={() => setOpen({ status: true, user: 2 })}>
                {Dictionary.get("WEBAPP_IFORTUNEFRAME_CHANGE_SEX")}
              </p>
            </li>
          </ul>
        </div>
        {open && (
          <SimpleModal
            classes="container-modal"
            text="Lorem Ipsum"
            status={open.status}
            user={open.user}
            onClose={() => setOpen({ status: false })}
          ></SimpleModal>
        )}

        {userInfo.sexA.sex && userInfo.sexB.sex && (
          <button onClick={() => setUserInfo({ ...userInfo, step: 2 })}>
            {Dictionary.get("WEBAPP_IFORTUNEFRAME_NEXT_STEP")}
          </button>
        )}
      </div>
    </React.Fragment>
  );
}
